import React from 'react'
import styled from 'styled-components'
import { StyledButton } from '../styles/SharedStyles'
import { MdOutlineDownloadForOffline } from 'react-icons/md'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const ButtonBox = styled.div`
  margin-top: 0.5rem;
`
const DownloadPdfButton = ({ code, language }) => {
  const { t } = useTranslation()
  const lang = language === 'en' ? '' : '_fr'
  const fileName = `${code.toLowerCase()}${lang}.pdf`
  //const fileName = `test${lang}.pdf`
  return (
    <ButtonBox>
      <a
        style={{ textDecoration: 'none' }}
        href={`/pdfs/${fileName}`}
        download //={`${fileName}`}
      >
        <StyledButton className="download">
          <div className="space">{t('download pdf')}</div>
          <MdOutlineDownloadForOffline />
        </StyledButton>
      </a>
    </ButtonBox>
  )
}

export default DownloadPdfButton
