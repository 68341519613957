import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { format } from 'd3-format'
import styled from 'styled-components'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'
import { GoalId } from '../../styles/OverviewStyles'
import {
  GoalTitle,
  LabelBox,
  NotMeasured,
  Disclaimer
} from '../../styles/CountryPageStyles'
//import { wrangle21Data } from '../../utils/helpers'
import DataSource from '../DataSource'
import { useTranslation } from 'react-i18next'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`
// const NotMeasured = styled.div`
//   text-align: center;
//   font-size: 0.9rem;
//   width: 6.25rem;
// `

const Ig21CountryPageChart = ({
  data,
  region,
  country,
  content,
  compareTo,
  dataSrc,
  isClient
}) => {
  const { t } = useTranslation()
  const formatThous = format(',.0f')
  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries(filter: { IA2030_IND: { eq: "IG2.1" } }) {
        nodes {
          COUNTRY
          YEAR
          VALUE_STR
        }
      }
    }
  `)
  // const { rows } = useStaticQuery(graphql`
  //   query {
  //     rows: allGoogleIg21Sheet {
  //       nodes {
  //         id
  //         memberState
  //         iSO3
  //         region
  //         regionType
  //         zeroDose2019
  //         zeroDose2020
  //         zeroDose2021
  //         zeroDose2022
  //         zeroDose2023
  //         zeroDose2024
  //         zeroDose2025
  //         zeroDose2026
  //         zeroDose2027
  //         zeroDose2028
  //         zeroDose2029
  //       }
  //     }
  //   }
  // `)

  const countryData = rows.nodes
    .filter((item) => item.COUNTRY === country)
    .sort((a, b) => (a.YEAR < b.YEAR ? -1 : 1))

  const compareData = rows.nodes
    .filter((item) => item.COUNTRY === compareTo?.COUNTRY)
    .sort((a, b) => (a.YEAR < b.YEAR ? -1 : 1))

  // console.log('cty', countryData)
  // console.log('cd', compareData)
  //const formattedCountry = wrangle21Data(countryData, true)
  // const getCompareCountry = (data) => {
  //   if (data) {
  //     return wrangle21Data(data, true)
  //   } else return null
  // }
  //const compareCountry = compareData ? compareData: null//getCompareCountry(compareData)

  const getValue = (cty) => {
    if (cty.length) {
      return cty[cty.length - 1].VALUE_STR
    } else return null
  }
  //country
  const countryValue = getValue(countryData)
  const compareValue = getValue(compareData)
  //region
  const regionValue = data[data.length - 1].VALUE_NUM
  const matches = useMediaQuery({ query: '(max-width: 900px)' })
  const year = data[data.length - 1].YEAR
  return (
    <>
      <GoalId>2.1</GoalId>
      <GoalTitle className={'wide'}>
        {content.ig21Description}
        <Disclaimer>{content.ig21Disclaimer}</Disclaimer>
        <DataSource year={year} dataSrc={dataSrc} indicator={'ig2.1'} />
      </GoalTitle>
      {matches && <Flex></Flex>}
      <Flex className="side-border">
        {countryValue === -1 ? (
          <NotMeasured>{t('not measured at the country level')}</NotMeasured>
        ) : (
          <LabelBox
            color={'brightBlue'}
            bgColor={'lightestGray'}
            //border={countryValue === 'No Data'}
          >
            {countryValue.startsWith('<')
              ? countryValue
              : formatThous(+countryValue)}
          </LabelBox>
        )}
      </Flex>
      <Flex>
        {isClient && compareData.length && compareValue === -1 ? (
          <NotMeasured>{t('not measured in this country')}</NotMeasured>
        ) : isClient && compareData.length ? (
          <LabelBox
            bgColor={compareValue === -2 ? 'white' : 'lightestGray'}
            border={countryValue === -2}
            color={'darkGray'}
          >
            {compareValue.startsWith('<')
              ? compareValue
              : formatThous(+compareValue)}
          </LabelBox>
        ) : (
          <LabelBox
            bgColor={regionValue < 0 ? 'white' : 'lightestGray'}
            color={regionValue < 0 ? 'brightBlue' : 'darkGray'}
            border={regionValue < 0}
          >
            {regionValue < 0 ? t('no data') : formatThous(regionValue)}
          </LabelBox>
        )}
      </Flex>
    </>
  )
}

export default Ig21CountryPageChart
