import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
//import { PATHOGEN_LABELS_API } from '../../utils/const'
import { GoalId } from '../../styles/OverviewStyles'
import {
  GoalTitle,
  LabelBox,
  NotMeasured
} from '../../styles/CountryPageStyles'
import DataSource from '../DataSource'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  //border-bottom: 1px solid var(--lightGray);
  &:nth-child(n + 21) {
    border-bottom: none;
  }
  &.row-label {
    margin-left: 1rem;
    justify-content: left;
    ${Mq.sm} {
      font-size: 1rem;
    }
    ${Mq.xs} {
      font-size: 0.8rem;
      margin-left: 0;
    }
  }
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Ig13CountryPageChart = ({
  data,
  region,
  country,
  content,
  compareTo,
  dataSrc,
  isClient
}) => {
  const { t } = useTranslation()
  const matches = useMediaQuery({ query: '(max-width: 900px)' })

  //Taking out country level data -- they have decided not to show
  //getting countries for country menu
  // const { rows } = useStaticQuery(graphql`
  //   query {
  //     rows: allGoogleIg13Sheet {
  //       nodes {
  //         id
  //         memberState
  //         iSO3
  //         region
  //         regionType
  //         choleraBaseline
  //         cholera2018
  //         cholera2019
  //         cholera2020
  //         #cholera2021
  //         cholera2022
  //         cholera2023
  //         cholera2024
  //         cholera2025
  //         cholera2026
  //         cholera2027
  //         cholera2028
  //         cholera2029
  //         cholera2030
  //         ebolaBaseline
  //         ebola2018
  //         ebola2019
  //         ebola2020
  //         #ebola2021
  //         ebola2022
  //         ebola2023
  //         ebola2024
  //         ebola2025
  //         ebola2026
  //         ebola2027
  //         ebola2028
  //         ebola2029
  //         ebola2030
  //         measlesBaseline
  //         measles2018
  //         measles2019
  //         measles2020
  //         #measles2021
  //         measles2022
  //         measles2023
  //         measles2024
  //         measles2025
  //         measles2026
  //         measles2027
  //         measles2028
  //         measles2029
  //         measles2030
  //         meningBaseline
  //         mening2018
  //         mening2019
  //         mening2020
  //         #mening2021
  //         mening2022
  //         mening2023
  //         mening2024
  //         mening2025
  //         mening2026
  //         mening2027
  //         mening2028
  //         mening2029
  //         mening2030
  //         cVDPVBaseline
  //         cVDPV2018
  //         cVDPV2019
  //         cVDPV2020
  //         #cVDPV2021
  //         cVDPV2022
  //         cVDPV2023
  //         cVDPV2024
  //         cVDPV2025
  //         cVDPV2026
  //         cVDPV2027
  //         cVDPV2028
  //         cVDPV2029
  //         cVDPV2030
  //         wPVBaseline
  //         wPV2018
  //         wPV2019
  //         wPV2020
  //         #wPV2021
  //         wPV2022
  //         wPV2023
  //         wPV2024
  //         wPV2025
  //         wPV2026
  //         wPV2027
  //         wPV2028
  //         wPV2029
  //         wPV2030
  //         yellowFeverBaseline
  //         yellowFever2018
  //         yellowFever2019
  //         yellowFever2020
  //         #yellowFever2021
  //         yellowFever2022
  //         yellowFever2023
  //         yellowFever2024
  //         yellowFever2025
  //         yellowFever2026
  //         yellowFever2027
  //         yellowFever2028
  //         yellowFever2029
  //         yellowFever2030
  //       }
  //     }
  //   }
  // `)

  // const countryData = rows.nodes.find((item) => item.iSO3 === country)
  // const compareData = rows.nodes.find((item) => item.iSO3 === compareTo?.iSO3)

  // const formattedCountry = wrangle13Data(countryData)
  // const getCompareCountry = (data) => {
  //   if (data) {
  //     return wrangle13Data(data)
  //   } else return null
  // }
  // const compareCountry = getCompareCountry(compareData)

  // const getValue = (cty, pathogen) => {
  //   if (cty) {
  //     //anticipating that we might need to enter -2 for missing data b/c wrangle function leaves out null fields
  //     return cty[pathogen][cty[pathogen].length - 1].value === -2
  //       ? 'No Data'
  //       : cty[pathogen][cty[pathogen].length - 1].value
  //   } else return null
  // }

  // const order = [
  //   'CHOLERA',
  //   'EBOLA',
  //   'MEASLES',
  //   'MENINGOCOCCUS',
  //   'POLIO_VAC',
  //   'POLIO_WILD',
  //   'YFEVER'
  // ]

  const sorted = data.sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1))
  const year = data[data.length - 1].YEAR
  const mostRecentValue = sorted[sorted.length - 1]

  return (
    <>
      <GoalId>1.3</GoalId>
      <GoalTitle className="wide">
        {content.ig13Description}
        <DataSource year={year} dataSrc={dataSrc} indicator={'ig1.3'} />
      </GoalTitle>

      {matches && <Flex></Flex>}
      <Flex className="side-border">
        <NotMeasured>{t('not shown at the country level')}</NotMeasured>
      </Flex>
      <Flex>
        {isClient && compareTo ? (
          <NotMeasured>{t('not shown at the country level')}</NotMeasured>
        ) : (
          <LabelBox
            bgColor={mostRecentValue.VALUE_NUM < 0 ? 'white' : 'lightestGray'}
            color={mostRecentValue.VALUE_NUM < 0 ? 'brightBlue' : 'darkGray'}
            border={mostRecentValue.VALUE_NUM < 0}
          >
            {mostRecentValue.VALUE_NUM < 0
              ? t('no data')
              : mostRecentValue.VALUE_NUM}
          </LabelBox>
        )}
      </Flex>
    </>
  )
}

export default Ig13CountryPageChart
