import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'
import { GoalId } from '../../styles/OverviewStyles'
import { GoalTitle, NotMeasured } from '../../styles/CountryPageStyles'
import { useTranslation } from 'react-i18next'

// const LabelBox = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 6.25rem;
//   height: 2.75rem;
//   background-color: ${({ bgColor }) => `var(--${bgColor})`};
//   color: ${({ color }) => `var(--${color})`};
//   border-radius: 0.25rem;
//   font-family: 'Bebas Neue';
//   font-size: ${({ size }) => (size === 'sm' ? '1.4rem' : '1.75rem')};
//   font-weight: normal;
//   border: ${({ border }) => (border ? '1px solid var(--brightBlue)' : 'none')};
// `

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`
// const NotMeasured = styled.div`
//   text-align: center;
//   font-size: 0.9rem;
//   width: 6.25rem;
// `
const Sp61CountryPageChart = ({ content, compareTo }) => {
  const { t } = useTranslation()
  const matches = useMediaQuery({ query: '(max-width: 900px)' })
  return (
    <>
      <GoalId>6.1</GoalId>
      <GoalTitle className={'wide'}>{content.sp61Description}</GoalTitle>
      {matches && <Flex></Flex>}
      <Flex className="side-border">
        <NotMeasured>{t('not measured at the country level')}</NotMeasured>
      </Flex>
      <Flex>
        <NotMeasured>
          {compareTo
            ? t('not measured at the country level')
            : t('not measured at the regional level')}
        </NotMeasured>
      </Flex>
    </>
  )
}

export default Sp61CountryPageChart
