import { group } from 'd3-array'

//Keeping this in separate copy -- slightly diff than overview chart. This one just shows recent data and does not show cumulative
export const wrangleIg11 = (data) => {
  // const allYears = []
  const total = Object.entries(data)
    .map(([key, value]) => {
      const keysToExclude = ['memberState', 'iSO3', 'id']
      if (!keysToExclude.includes(key) && value !== null) {
        const year = +key.slice(-4)

        return { year, value }
      } else return null
    })
    .filter((item) => item)
  // .reduce(
  //   (acc, d) => {
  //     acc['total'] += d.year
  //     return acc
  //   },
  //   { total: 0 }
  // )
  return total
}

export const wrangleIg12CountryData = (data) => {
  data.forEach((d) => {
    const pathogen = d.INDCODE.split('_')[2]
    d.pathogen = pathogen
  })
  const dataMap = group(data, (d) => d.pathogen)

  const groupedByPathogen = Array.from(dataMap.values())

  return groupedByPathogen.map((p) => {
    const yearMap = group(p, (d) => d.YEAR)
    const groupedByYear = Array.from(yearMap.values())

    const yearData = groupedByYear.map((y) => {
      return y.reduce((acc, d) => {
        acc['year'] = d.YEAR
        acc['pathogen'] = d.pathogen
        acc['value'] = d.VALUE_STR.toLowerCase()
        return acc
      }, {})
    })
    return yearData.sort((a, b) => (a.year > b.year ? 1 : -1))
  })
}

// export const wrangle21Data = (data) => {
//   return Object.entries(data)
//     .map(([key, value]) => {
//       const keysToExclude = [
//         'memberState',
//         'iSO3',
//         'id',
//         'region',
//         'regionType'
//       ]
//       if (!keysToExclude.includes(key) && value !== null) {
//         const year = +key.slice(-4)

//         return { year, value }
//       } else return null
//     })
//     .filter((item) => item)
// }
