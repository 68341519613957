import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { CHART_TITLES_IG12_API } from '../../utils/const'
import { useStaticQuery, graphql } from 'gatsby'
import { wrangleIg12CountryData } from '../../utils/country-page-helpers'
import { GoalId } from '../../styles/OverviewStyles'
import { GoalTitle, LabelBox } from '../../styles/CountryPageStyles'
import DataSource from '../DataSource'
import { useTranslation } from 'react-i18next'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid var(--lightGray);
  &:nth-child(n + ${({ num }) => num}) {
    border-bottom: none;
  }
  &.row-label {
    margin-left: 1rem;
    justify-content: left;
    ${Mq.sm} {
      font-size: 1rem;
    }
    ${Mq.xs} {
      font-size: 0.8rem;
      margin-left: 0;
    }
  }
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Ig12CountryPageChart = ({
  data,
  region,
  country,
  content,
  numRows,
  compareTo,
  dataSrc,
  isClient
}) => {
  const { t } = useTranslation()
  //const numCountries = NUM_COUNTRIES_IN_REG[region] //number of countries in region
  //getting countries for country menu
  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries2(filter: { IA2030_IND: { eq: "IG1.2" } }) {
        nodes {
          COUNTRY
          YEAR
          INDCODE
          VALUE_STR
        }
      }
    }
  `)
  // console.log('test cnty', wrangleIg12Data(test))
  const countryData = rows.nodes.filter((item) => item.COUNTRY === country)
  const compareData = rows.nodes.filter(
    (item) => item.COUNTRY === compareTo?.COUNTRY
  )
  const formattedCountry = wrangleIg12CountryData(countryData)
  const getCompareCountry = (data) => {
    if (data) {
      return wrangleIg12CountryData(data)
    } else return null
  }
  const compareCountry = getCompareCountry(compareData)
  const getValue = (cty, pathogen) => {
    if (cty && cty.length) {
      const x = cty.filter((c) => c[0].pathogen === pathogen)
      return x[0][x.length - 1].value
    } else return null
  }

  const LABEL_COLORS = {
    'no target': 'white',
    reached: 'brightBlue',
    'not reached': 'darkOrange'
  }
  const cellNum = 4 * (numRows - 1)

  const year = formattedCountry[0][formattedCountry[0].length - 1].year

  const order = ['MEASLES', 'RUBELLA', 'POLIO', 'NTETANUS']
  data.sort((a, b) =>
    order.indexOf(a[0].pathogen) > order.indexOf(b[0].pathogen) ? 1 : -1
  )
  return (
    <>
      <GoalId>1.2</GoalId>
      <GoalTitle className={'tall'}>
        {content.ig12Description}

        <DataSource year={year} dataSrc={dataSrc} indicator={'ig1.2'} />
      </GoalTitle>
      {data.map((value, i) => {
        const pathogen = value[0].pathogen
        //country
        const labelValue = getValue(formattedCountry, pathogen)
        const compareValue = getValue(compareCountry, pathogen)
        //region
        const mostRecentValue = value[value.length - 1]
        const numerator =
          mostRecentValue.Achieved === undefined ? 0 : mostRecentValue.Achieved
        const denominator = mostRecentValue.denominator
        return (
          <React.Fragment key={value[0].pathogen}>
            <Flex className="row-label" num={cellNum}>
              {t(CHART_TITLES_IG12_API[value[0].pathogen])}
            </Flex>
            <Flex className="side-border" num={cellNum}>
              <LabelBox
                bgColor={LABEL_COLORS[labelValue]}
                border={labelValue === 'no target'}
                color={labelValue !== 'no target' ? 'white' : 'brightBlue'}
                size={labelValue === 'not reached' ? 'sm' : ''}
              >
                {t(labelValue)}
              </LabelBox>
            </Flex>
            <Flex num={cellNum}>
              {isClient && compareCountry.length ? (
                <LabelBox
                  bgColor={LABEL_COLORS[compareValue]}
                  border={compareValue === 'no target'}
                  color={compareValue !== 'no target' ? 'white' : 'brightBlue'}
                  size={compareValue === 'not reached' ? 'sm' : ''}
                >
                  {t(compareValue)}
                </LabelBox>
              ) : (
                <LabelBox bgColor={'lightestGray'}>
                  {`${numerator} / ${denominator}`}
                </LabelBox>
              )}
            </Flex>
          </React.Fragment>
        )
      })}
    </>
  )
}

export default Ig12CountryPageChart
