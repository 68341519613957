import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
import { MediaQueries as Mq } from '../styles/MediaQueries'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { clearQueryParam } from 'react-use-query-param-string'
import { useTranslation } from 'react-i18next'

const StyledTextField = styled(TextField)`
  //position: relative;
  .MuiInputBase-root {
    background-color: var(--brightBlue);
    height: 2.5rem;
    color: var(--white);
    padding-right: 0;
  }
  .MuiInputBase-input {
    font-size: 1.2rem;
    padding: 9px 4.5px;
    ::-webkit-input-placeholder {
      /* Edge */
      color: var(--white);
      opacity: 1;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--white);
      opacity: 1;
    }
    ::placeholder {
      color: var(--white);
      opacity: 1;
    }
    ${Mq.xs} {
      font-size: 1.1rem;
    }
  }
  .MuiOutlinedInput-root {
    //color: var(--white);
    // &:hover {
    //   border: 1px solid red;
    // }
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 9px;
    &:hover {
      border: 1px solid var(--baseBlue);
    }
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input {
    padding: 4.5px 4px;
  }
  .MuiIconButton-root {
    color: var(--white);
  }
`
const Directive = styled.div`
  position: absolute;
  top: -1.75rem;
  right: 0;
  left: unset;
  font-size: 0.9rem;
  line-height: 1.2;
  ${Mq.md} {
    left: 0;
    top: -2.25rem;
  }
`

const Container = styled.div`
  position: relative;
  ${Mq.md} {
    margin-top: 3rem;
  }
`
const StyledAutocomplete = styled(Autocomplete)`
  &.MuiAutocomplete-root {
    width: 400px;
    min-width: 300px;
    ${Mq.xs} {
      width: 340px;
      min-width: 300px;
    }
  }
`
const CompareCountry = ({ compareTo, setCompare, countries }) => {
  //console.log('in compare', compareTo)
  //console.log('also', countries)
  const { t } = useTranslation()
  return (
    <Container>
      <Directive>{t('press the X to reset to regional comparisons')}</Directive>
      <StyledAutocomplete
        id="country-compare"
        disablePortal
        value={compareTo}
        onChange={(event, newValue) => {
          if (!newValue) clearQueryParam('compare')
          else setCompare(newValue.COUNTRY)
        }}
        //sx={{ width: 400, minWidth: 300 }}
        options={countries}
        autoHighlight
        getOptionLabel={(option) => t(option.COUNTRY_NAME)}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="outlined"
            placeholder={t('compare against another country')}
          />
        )}
      />
    </Container>
  )
}

export default CompareCountry
