import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import {
  DataSrc,
  DataSrcYear,
  DataSrcLink,
  DataSrcContainer
} from '../styles/CountryPageStyles'
import { useTranslation } from 'react-i18next'

const DataSource = ({ year, dataSrc, indicator }) => {
  const { t } = useTranslation()
  return (
    <DataSrcContainer>
      <DataSrcYear>{year}</DataSrcYear>&nbsp;
      <DataSrc>{dataSrc}:</DataSrc>&nbsp;
      <DataSrcLink to={`/${indicator}#ds`}>{t('learn more')}</DataSrcLink>
    </DataSrcContainer>
  )
}

export default DataSource
