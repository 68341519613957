import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'
import { GoalId } from '../../styles/OverviewStyles'
//import { wrangleSP16Data } from '../../utils/helpers'
import { GoalTitle, LabelBox } from '../../styles/CountryPageStyles'
import DataSource from '../DataSource'
//import { NUM_COUNTRIES_IN_REG, GBL_NUM_COUNTRIES } from '../../utils/const'
import { useTranslation } from 'react-i18next'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Sp16CountryPageChart = ({
  data,
  test,
  region,
  country,
  content,
  compareTo,
  dataSrc,
  isClient
}) => {
  const { t } = useTranslation()
  //const numCountries =
  // region === 'global' ? GBL_NUM_COUNTRIES : NUM_COUNTRIES_IN_REG[region] //number of countries
  const { rows } = useStaticQuery(graphql`
    query {
      rows: allCountries(filter: { IA2030_IND: { eq: "SP1.6" } }) {
        nodes {
          COUNTRY
          YEAR
          VALUE_STR
        }
      }
    }
  `)
  const countryData = rows.nodes
    .filter((item) => item.COUNTRY === country)
    .sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1))
  const compareData = rows.nodes
    .filter((item) => item.COUNTRY === compareTo?.COUNTRY)
    .sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1))
  //.sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1))
  // const formattedCountry = wrangleSP16Data(countryData)
  // const getCompareCountry = (data) => {
  //   if (data) {
  //     return wrangleSP16Data(data)
  //   } else return null
  // }
  // const compareCountry = getCompareCountry(compareData)

  const getValue = (cty) => {
    //-2 means missing in this country
    if (cty && cty.length) {
      return cty[cty.length - 1].VALUE_STR
    } else return null
  }
  // //country
  const countryValue = getValue(countryData)
  const compareValue = getValue(compareData)

  // //region
  const regionValue = data[data.length - 1].VALUE_NUM
  const regionNumerator = data[data.length - 1].NUMERATOR
  const regionDenominator = data[data.length - 1].DENOMINATOR

  const matches = useMediaQuery({ query: '(max-width: 900px)' })
  const year = data[data.length - 1].YEAR

  return (
    <>
      <GoalId>1.6</GoalId>
      <GoalTitle className={'wide'}>
        {content.sp16Description}
        <DataSource year={year} dataSrc={dataSrc} indicator={'sp1.6'} />
      </GoalTitle>
      {matches && <Flex></Flex>}
      <Flex className="side-border">
        <LabelBox
          color={
            countryValue === 'YES' || countryValue === 'NO'
              ? 'white'
              : 'brightBlue'
          }
          bgColor={
            countryValue === 'YES'
              ? 'brightBlue'
              : countryValue === 'NO'
              ? 'darkOrange'
              : 'white'
          }
          border={countryValue === 'no data'}
        >
          {t(countryValue)}
        </LabelBox>
      </Flex>
      <Flex>
        {isClient && compareData.length ? (
          <LabelBox
            color={
              compareValue === 'YES' || compareValue === 'NO'
                ? 'white'
                : 'brightBlue'
            }
            bgColor={
              compareValue === 'YES'
                ? 'brightBlue'
                : compareValue === 'NO'
                ? 'darkOrange'
                : 'white'
            }
            border={compareValue === 'no data'}
          >
            {t(compareValue)}
          </LabelBox>
        ) : (
          <LabelBox
            bgColor={regionValue < 0 ? 'white' : 'lightestGray'}
            color={regionValue < 0 ? 'brightBlue' : 'darkGray'}
            border={regionValue < 0}
          >
            {regionValue < 0
              ? t('no data')
              : `${regionNumerator} / ${regionDenominator}`}
          </LabelBox>
        )}
      </Flex>
    </>
  )
}

export default Sp16CountryPageChart
