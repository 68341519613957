import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'
import { GoalId } from '../../styles/OverviewStyles'
import { GoalTitle, NotMeasured } from '../../styles/CountryPageStyles'
import { useTranslation } from 'react-i18next'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Sp51CountryPageChart = ({ content, compareTo }) => {
  const { t } = useTranslation()
  const matches = useMediaQuery({ query: '(max-width: 900px)' })

  return (
    <>
      <GoalId>5.1</GoalId>
      <GoalTitle className={'wide'}>{content.sp51Description}</GoalTitle>
      {matches && <Flex></Flex>}
      <Flex className="side-border">
        <NotMeasured>{t('not measured at the country level')}</NotMeasured>
      </Flex>
      <Flex>
        <NotMeasured>
          {compareTo
            ? t('not measured at the country level')
            : t('not measured at the regional level')}
        </NotMeasured>
      </Flex>
    </>
  )
}

export default Sp51CountryPageChart
