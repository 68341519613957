import React, { useState, useEffect } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import { MediaQueries as Mq } from '../styles/MediaQueries'
//import TextField from '@mui/material/TextField'
//import Autocomplete from '@mui/material/Autocomplete'
import PageHeader from '../components/PageHeader'
//import Header from '../components/Header'
import { MdHeadline } from '../styles/SharedStyles'
import {
  OverviewContainer,
  IntroText,
  GoalContainer,
  SectionTitle,
  GoalBox,
  GoalId,
  NoData,
  NoDataPlaceholder
} from '../styles/OverviewStyles'
import { GoalTitle } from '../styles/CountryPageStyles'
import CompareCountry from '../components/CompareCountry'
import DownloadPdfButton from '../components/DownloadPdfButton'
import Ig11CountryPageChart from '../components/CountryPageCharts/Ig11CountryPageChart'
import Ig12CountryPageChart from '../components/CountryPageCharts/Ig12CountryPageChart'
import Ig13CountryPageChart from '../components/CountryPageCharts/Ig13CountryPageChart'
import Ig21CountryPageChart from '../components/CountryPageCharts/Ig21CountryPageChart'
import Ig22CountryPageChart from '../components/CountryPageCharts/Ig22CountryPageChart'
import Ig31CountryPageChart from '../components/CountryPageCharts/Ig31CountryPageChart'
import Ig32CountryPageChart from '../components/CountryPageCharts/Ig32CountryPageChart'
import Sp12CountryPageChart from '../components/CountryPageCharts/Sp12CountryPageChart'
import Sp16CountryPageChart from '../components/CountryPageCharts/Sp16CountryPageChart'
import Sp21CountryPageChart from '../components/CountryPageCharts/Sp21CountryPageChart'
import Sp22CountryPageChart from '../components/CountryPageCharts/Sp22CountryPageChart'
//import Sp32CountryPageChart from '../components/CountryPageCharts/Sp32CountryPageChart'
import Sp32CountryPageChart from '../components/CountryPageCharts/Sp32CountryPageChart'
import Sp41CountryPageChart from '../components/CountryPageCharts/Sp41CountryPageChart'
import Sp51CountryPageChart from '../components/CountryPageCharts/Sp51CountryPageChart'
import Sp61CountryPageChart from '../components/CountryPageCharts/Sp61CountryPageChart'
//import Sp62CountryPageChart from '../components/CountryPageCharts/Sp62CountryPageChart'
//import Sp63CountryPageChart from '../components/CountryPageCharts/Sp63CountryPageChart'
//import Sp71CountryPageChart from '../components/CountryPageCharts/Sp71CountryPageChart'
import {
  wrangleIg12Data,
  //wrangleIg13Data,
  //wrangle21Data,
  //wrangle22Data,
  wrangle31Data,
  //wrangle32Data,
  //wrangleSP12Data,
  //wrangleSP16Data,
  //wrangleSPResponseData,
  wrangleSpRegionResponseData,
  wrangleSp32Data
  //wrangleSp41DataAPI,
  ////wrangleSP62Data
} from '../utils/helpers'
//import Notes from '../components/Notes'
import Footer from '../components/Footer'
import HowToRead from '../components/HowToRead'
//import { group } from 'd3-array'

const HeaderLabels = styled.div`
  position: relative;
  width: calc(100% - 2rem);
  background-color: var(--white);
  margin: 0 -1rem 0 2rem;
  padding: 0 0 0 2.5rem;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 1fr;
  // z-index: 5;
  ${Mq.md} {
    grid-template-columns: 3fr 1fr 1fr;
  }
  ${Mq.sm} {
    margin: 0 0rem 0rem 1rem;
    grid-template-columns: 1.5fr 1fr 1fr;
  }

  .wide {
    background-color: transparent;
    grid-column: 1 / span 2;
    ${Mq.md} {
      grid-column: unset;
    }
  }
`
const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  font-size: 1.5rem;
  font-weight: ${({ region }) => (region ? 'normal' : 'bold')};
  color: ${({ region }) => (region ? `var(--darkGray)` : `var(--brightBlue)`)};
  white-space: ${({ nowrap }) => (nowrap ? 'nowrap' : '')};
  line-height: 1.3;
  ${Mq.lg} {
    font-size: 1.35rem;
  }
  ${Mq.sm} {
    width: 5.75rem;
    font-size: 1.3rem;
    white-space: unset;
  }
  ${Mq.xs} {
    width: 3.25rem;
    font-size: 1rem;
  }
`

const CountryBox = styled.div`
  position: relative;
  width: calc(100% - 2rem);
  background-color: var(--white);
  border: 1px solid var(--lightGray);
  border-radius: 5px;
  margin: 0 -1rem 1rem 2rem;
  padding: 0 0 0 2.5rem;
  display: grid;
  grid-template-columns: 2fr 3fr 1fr 1fr;

  ${Mq.md} {
    grid-template-columns: 3fr 1fr 1fr;
  }
  ${Mq.sm} {
    margin: 0 0rem 1rem 1rem;
    grid-template-columns: 1.5fr 1fr 1fr;
  }
  &:hover {
    border: 1px solid var(--brightBlue);
    //background-color: var(--lightestGray);
  }
  //for when indicator title needs to span box
  .wide {
    grid-column: 1 / span 2;
    ${Mq.md} {
      grid-column: 1/-1;
      grid-row: unset;
    }
  }
  //for when indicator title needs to occupy first column
  .tall {
    grid-row: 1 / ${({ colHeight }) => colHeight};
    ${Mq.md} {
      grid-column: 1/-1;
      grid-row: unset;
    }
  }
`
const HeaderFlex = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 10px 10px 0 10px;
  // &.side-border {
  //   border-right: 1px solid var(--lightGray);
  // }
`
const Flex = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 2rem 0 1rem 0;
  ${Mq.md} {
    flex-wrap: wrap;
  }
`
const WHOLabel = styled.div`
  position: absolute;
  top: 2px;
  right: 0;
  font-size: 12px;
  ${Mq.sm} {
    right: 1rem;
  }
  ${Mq.xs} {
    font-size: 9.5px;
  }
`
const CountryPage = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const {
    countryName,
    region,
    //wisemartRegion,
    iSO3,
    incomeGroup,
    unicefRegion,
    language
  } = pageContext
  const {
    content,
    footerContent,
    countryList,
    ig11Region,
    ig12Region,
    ig13Region,
    ig21Region,
    ig22Region,
    ig31Region,
    ig32Region,
    sp12Region,
    sp16Region,
    sp21Region,
    sp22Region,
    sp32Region,
    sp41Region,
    //sp62Region,
    //sp71Region,
    ig11DataSrc,
    ig12DataSrc,
    ig13DataSrc,
    ig21DataSrc,
    ig22DataSrc,
    ig31DataSrc,
    ig32DataSrc,
    sp12DataSrc,
    sp16DataSrc,
    sp21DataSrc,
    sp22DataSrc,
    sp32DataSrc,
    sp41DataSrc
    //sp61DataSrc,
    //sp62DataSrc,
    //sp63DataSrc,
    //sp71DataSrc
  } = data
  // const countries = allCountries?.nodes.filter(
  //   (node) => node.regionType === 'CTY'
  // )

  const countries = countryList.nodes.sort((a, b) =>
    a.COUNTRY_NAME < b.COUNTRY_NAME ? -1 : 1
  )

  const params = getQueryParams()
  const [compare, setCompare] = useQueryParamString('compare', params.compare)
  const country = countries?.find((item) => item.COUNTRY === compare) || null
  const ig11Data = ig11Region.nodes //wrangleIg11(ig11Region)
  const ig12Data = wrangleIg12Data(ig12Region.nodes)
  const ig12NumRows = ig12Data.length //this one is variable depending on what data comes in
  const ig13Data = ig13Region.nodes
  const ig21Data = ig21Region.nodes.sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1)) //wrangle21Data(ig21Region)
  const ig22Data = ig22Region.nodes.sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1)) //wrangle22Data(ig22Region)
  const ig31Data = wrangle31Data(ig31Region.nodes)
  const ig32Data = ig32Region.nodes.sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1)) //wrangle32Data(ig32Region)
  const sp12Data = sp12Region.nodes.sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1)) //wrangleSP12Data(sp12Region)
  const sp16Data = sp16Region.nodes // wrangleSP16Data(sp16Region)
  //const sp21Data = wrangleSPResponseData(sp21Region)
  const sp21Data = wrangleSpRegionResponseData(sp21Region.nodes)
  const sp22Data = wrangleSpRegionResponseData(sp22Region.nodes)
  //const sp32Data = wrangleSP32Data(sp32Region)
  const sp32Data = wrangleSp32Data(sp32Region.nodes)
  //wrangleSP32Data(sp32Region)

  const sp41Data = sp41Region.nodes.sort((a, b) => (a.YEAR > b.YEAR ? 1 : -1)) //no wrangling needed
  //const sp62Data = wrangleSP62Data(sp62Region)
  //const sp71Data = wrangleSpRegionResponseData(sp71Region.nodes)

  const [isClient, setClient] = useState(false)
  useEffect(() => {
    setClient(true)
  }, [])
  const hasPdf = [
    'ago',
    'cmr',
    'tcd',
    'civ',
    'cod',
    'ken',
    'lbr',
    'mdg',
    'mwi',
    'mli',
    'moz',
    'nga',
    'rwa',
    'sen',
    'zaf',
    'ssd',
    'uga',
    'bra',
    'hti',
    'mex',
    'ind',
    'idn',
    'mmr',
    'npl',
    'afg',
    'pak',
    'som',
    'png',
    'phl',
    'bgd',
    'eth',
    'gha',
    'tza',
    'yem',
    'zwe',
    'zmb'
  ].includes(iSO3.toLowerCase())

  return (
    <>
      <PageHeader
        sectionId={'CTY'}
        title={countryName}
        region={region}
        incomeGroup={incomeGroup}
        unicefRegion={unicefRegion}
      />
      <OverviewContainer>
        {/* Impact Goals */}
        <MdHeadline color={'baseBlue'}>
          {t('Impact Goal Indicators')}
        </MdHeadline>

        <Flex>
          <div>
            <IntroText
              p={'0rem'}
              mb={'1rem'}
              dangerouslySetInnerHTML={{ __html: content.igDataIntroRt }}
            />
            {hasPdf && <DownloadPdfButton code={iSO3} language={language} />}
          </div>
          {isClient && (
            <CompareCountry
              countries={countries}
              compareTo={country}
              setCompare={setCompare}
            />
          )}
        </Flex>
        <HeaderLabels>
          <div className="wide"></div>

          <HeaderFlex className="side-border">
            <StyledLabel>{t(countryName)}</StyledLabel>
          </HeaderFlex>
          <HeaderFlex>
            <StyledLabel
              region={true}
              nowrap={isClient && country ? false : true}
            >
              {country ? t(country.COUNTRY_NAME) : `${region} ${t('region')}`}
            </StyledLabel>
          </HeaderFlex>
        </HeaderLabels>
        <GoalContainer>
          {!country && <WHOLabel>{t('(WHO Region)')}</WHOLabel>}
          {/* This is for spacing: */}
          <SectionTitle></SectionTitle>

          <CountryBox>
            <Ig11CountryPageChart
              data={ig11Data}
              //test={ig11Test}
              dataSrc={ig11DataSrc.dataSource}
              content={content}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <CountryBox colHeight={ig12NumRows + 1}>
            <Ig12CountryPageChart
              content={content}
              data={ig12Data}
              dataSrc={ig12DataSrc.dataSource}
              region={region}
              country={iSO3}
              numRows={ig12NumRows}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <CountryBox colHeight={8}>
            <Ig13CountryPageChart
              content={content}
              data={ig13Data}
              dataSrc={ig13DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <CountryBox>
            <Ig21CountryPageChart
              content={content}
              data={ig21Data}
              dataSrc={ig21DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <CountryBox>
            <Ig22CountryPageChart
              content={content}
              data={ig22Data}
              dataSrc={ig22DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <CountryBox colHeight={5}>
            <Ig31CountryPageChart
              content={content}
              data={ig31Data}
              dataSrc={ig31DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <CountryBox>
            <Ig32CountryPageChart
              content={content}
              data={ig32Data}
              dataSrc={ig32DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
        </GoalContainer>

        {/* Strategic Priorites */}
        <MdHeadline color={'baseBlue'}>
          {t('Strategic Priority Indicators')}
        </MdHeadline>
        <Flex>
          <IntroText
            p={'0rem'}
            mb={'1rem'}
            maxWidth={'40rem'}
            dangerouslySetInnerHTML={{ __html: content.spDataIntroRt }}
          />
          {isClient && (
            <CompareCountry
              countries={countries}
              compareTo={country}
              setCompare={setCompare}
            />
          )}
        </Flex>
        <HowToRead type="cntyPg" />
        <HeaderLabels>
          <div className="wide"></div>

          <HeaderFlex className="side-border">
            <StyledLabel>{countryName}</StyledLabel>
          </HeaderFlex>
          <HeaderFlex>
            <StyledLabel region={true} nowrap={country ? false : true}>
              {country ? t(country.COUNTRY_NAME) : `${region} ${t('region')}`}
            </StyledLabel>
          </HeaderFlex>
        </HeaderLabels>
        <GoalContainer>
          <SectionTitle></SectionTitle>
          <GoalBox noData={true} country={true}>
            <GoalId>1.1</GoalId>
            <GoalTitle>{content.sp11Description}</GoalTitle>
            <NoDataPlaceholder>
              <NoData country={true}>{t('data not yet available')}</NoData>
            </NoDataPlaceholder>
          </GoalBox>
          <CountryBox>
            <Sp12CountryPageChart
              content={content}
              data={sp12Data}
              dataSrc={sp12DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <GoalBox noData={true} country={true}>
            <GoalId>1.3</GoalId>
            <GoalTitle>{content.sp13Description}</GoalTitle>
            <NoDataPlaceholder>
              <NoData country={true}>{t('data not yet available')}</NoData>
            </NoDataPlaceholder>
          </GoalBox>
          <GoalBox noData={true} country={true}>
            <GoalId>1.4</GoalId>
            <GoalTitle>{content.sp14Description}</GoalTitle>
            <NoDataPlaceholder>
              <NoData country={true}>{t('data not yet available')}</NoData>
            </NoDataPlaceholder>
          </GoalBox>
          <CountryBox>
            <Sp16CountryPageChart
              content={content}
              data={sp16Data}
              dataSrc={sp16DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <CountryBox>
            <Sp21CountryPageChart
              content={content}
              data={sp21Data}
              dataSrc={sp21DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <CountryBox>
            <Sp22CountryPageChart
              content={content}
              data={sp22Data}
              dataSrc={sp22DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <CountryBox colHeight={4}>
            <Sp32CountryPageChart
              content={content}
              regionData={sp32Data}
              dataSrc={sp32DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <CountryBox>
            <Sp41CountryPageChart
              content={content}
              data={sp41Data}
              dataSrc={sp41DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox>
          <CountryBox>
            <Sp51CountryPageChart content={content} compareTo={country} />
          </CountryBox>
          <CountryBox>
            <Sp61CountryPageChart content={content} compareTo={country} />
          </CountryBox>
          {/* <CountryBox>
            <Sp62CountryPageChart
              content={content}
              // data={sp62Data}
              dataSrc={sp62DataSrc.dataSource}
              //region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox> */}
          <GoalBox noData={true} country={true}>
            <GoalId>6.2</GoalId>
            <GoalTitle>{content.sp62Description}</GoalTitle>
            <NoDataPlaceholder>
              <NoData country={true}>{t('data not yet available')}</NoData>
            </NoDataPlaceholder>
          </GoalBox>
          {/* <CountryBox>
            <Sp63CountryPageChart
              dataSrc={sp63DataSrc.dataSource}
              content={content}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox> */}
          <GoalBox noData={true} country={true}>
            <GoalId>6.3</GoalId>
            <GoalTitle>{content.sp63Description}</GoalTitle>
            <NoDataPlaceholder>
              <NoData country={true}>{t('data not yet available')}</NoData>
            </NoDataPlaceholder>
          </GoalBox>
          {/* <CountryBox>
            <Sp71CountryPageChart
              content={content}
              data={sp71Data}
              dataSrc={sp71DataSrc.dataSource}
              region={region}
              country={iSO3}
              compareTo={country}
              isClient={isClient}
            />
          </CountryBox> */}
          <GoalBox noData={true} country={true}>
            <GoalId>7.1</GoalId>
            <GoalTitle>{content.sp71Description}</GoalTitle>
            <NoDataPlaceholder>
              <NoData country={true}>{t('data not yet available')}</NoData>
            </NoDataPlaceholder>
          </GoalBox>
          <GoalBox noData={true} country={true}>
            <GoalId>7.2</GoalId>
            <GoalTitle>{content.sp72Description}</GoalTitle>
            <NoDataPlaceholder>
              <NoData country={true}>{t('data not yet available')}</NoData>
            </NoDataPlaceholder>
          </GoalBox>
        </GoalContainer>
      </OverviewContainer>
      {/* <Notes content={content} /> */}
      <Footer content={footerContent} />
    </>
  )
}

export default CountryPage

export const query = graphql`
  query ($wisemartRegion: String, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleCountryPageCmsSheet(language: { eq: $language }) {
      igDataIntroRt
      ig11Description
      ig12Description
      ig13Description
      ig21Description
      ig21Disclaimer
      ig22Description
      ig31Description
      ig32Description
      spDataIntroRt
      sp11Description
      sp12Description
      sp13Description
      sp14Description
      sp16Description
      sp21Description
      sp22Description
      sp32Description
      sp41Description
      sp51Description
      sp61Description
      sp62Description
      sp63Description
      sp71Description
      sp72Description
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }

    countryList: allCountries(
      filter: { IA2030_IND: { eq: "IG2.1" }, YEAR: { eq: 2019 } }
    ) {
      nodes {
        COUNTRY
        COUNTRY_NAME
      }
    }
    ig11DataSrc: googleIg11CmsSheet(language: { eq: $language }) {
      dataSource
    }

    ig12DataSrc: googleIg12CmsSheet(language: { eq: $language }) {
      dataSource
    }
    ig13DataSrc: googleIg13CmsSheet(language: { eq: $language }) {
      dataSource
    }
    ig21DataSrc: googleIg21CmsSheet(language: { eq: $language }) {
      dataSource
    }
    ig22DataSrc: googleIg22CmsSheet(language: { eq: $language }) {
      dataSource
    }
    ig31DataSrc: googleIg31CmsSheet(language: { eq: $language }) {
      dataSource
    }
    ig32DataSrc: googleIg32CmsSheet(language: { eq: $language }) {
      dataSource
    }
    sp12DataSrc: googleSp12CmsSheet(language: { eq: $language }) {
      dataSource
    }
    sp16DataSrc: googleSp21CmsSheet(language: { eq: $language }) {
      dataSource
    }
    sp21DataSrc: googleSp21CmsSheet(language: { eq: $language }) {
      dataSource
    }
    sp22DataSrc: googleSp22CmsSheet(language: { eq: $language }) {
      dataSource
    }
    sp32DataSrc: googleSp32CmsSheet(language: { eq: $language }) {
      dataSource
    }
    sp41DataSrc: googleSp41CmsSheet(language: { eq: $language }) {
      dataSource
    }
    sp51DataSrc: googleSp51CmsSheet(language: { eq: $language }) {
      dataSource
    }
    sp61DataSrc: googleSp61CmsSheet(language: { eq: $language }) {
      dataSource
    }
    sp62DataSrc: googleSp62CmsSheet(language: { eq: $language }) {
      dataSource
    }
    sp63DataSrc: googleSp63CmsSheet(language: { eq: $language }) {
      dataSource
    }
    sp71DataSrc: googleSp71CmsSheet(language: { eq: $language }) {
      dataSource
    }
    ig11Region: allRegions2(
      filter: {
        IA2030_IND: { eq: "IG1.1" }
        CODE: { eq: $wisemartRegion }
        DIMENSION2: { eq: null }
      }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        VALUE_NUM
        DIMENSION2
      }
    }
    ig12Region: allRegions2(
      filter: { IA2030_IND: { eq: "IG1.2" }, CODE: { eq: $wisemartRegion } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        DIMENSION2
        VALUE_NUM
        NUMERATOR
        DENOMINATOR
      }
    }
    ig13Region: allRegions2(
      filter: {
        IA2030_IND: { eq: "IG1.3" }
        CODE: { eq: $wisemartRegion }
        DIMENSION2: { eq: null }
      }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        YEAR_RANGE
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }

    ig21Region: allRegions(
      filter: { IA2030_IND: { eq: "IG2.1" }, CODE: { eq: $wisemartRegion } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        VALUE_NUM
      }
    }
    ig22Region: allRegions(
      filter: {
        IA2030_IND: { eq: "IG2.2" }
        CODE: { eq: $wisemartRegion }
        DIMENSION2: { eq: null }
      }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        VALUE_NUM
        DIMENSION2
      }
    }

    ig31Region: allRegions(
      filter: { IA2030_IND: { eq: "IG3.1" }, CODE: { eq: $wisemartRegion } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }
    ig32Region: allRegions(
      filter: { IA2030_IND: { eq: "IG3.2" }, CODE: { eq: $wisemartRegion } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        VALUE_NUM
        YEAR
      }
    }
    sp12Region: allRegions(
      filter: { IA2030_IND: { eq: "SP1.2" }, CODE: { eq: $wisemartRegion } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        VALUE_NUM
        YEAR
      }
    }
    sp16Region: allRegions(
      filter: { IA2030_IND: { eq: "SP1.6" }, CODE: { eq: $wisemartRegion } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        VALUE_NUM
        YEAR
        NUMERATOR
        DENOMINATOR
      }
    }

    sp21Region: allRegions(
      filter: { IA2030_IND: { eq: "SP2.1" }, CODE: { eq: $wisemartRegion } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        DIMENSION2
        NUMERATOR
        DENOMINATOR
        VALUE_NUM
        YEAR
      }
    }
    sp22Region: allRegions(
      filter: { IA2030_IND: { eq: "SP2.2" }, CODE: { eq: $wisemartRegion } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        DIMENSION2
        NUMERATOR
        DENOMINATOR
        VALUE_NUM
        YEAR
      }
    }
    sp32Region: allRegions(
      filter: { IA2030_IND: { eq: "SP3.2" }, CODE: { eq: $wisemartRegion } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        DIMENSION2
        VALUE_NUM
        YEAR
      }
    }

    sp41Region: allRegions(
      filter: {
        IA2030_IND: { eq: "SP4.1" }
        CODE: { eq: $wisemartRegion }
        DIMENSION2: { eq: null }
      }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        YEAR
        INDCODE
        DIMENSION2
        VALUE_NUM
      }
    }
    sp71Region: allRegions(
      filter: { IA2030_IND: { eq: "SP7.1" }, CODE: { eq: $wisemartRegion } }
    ) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        DIMENSION2
        NUMERATOR
        DENOMINATOR
        VALUE_NUM
        YEAR
      }
    }
  }
`
//old
// ig11Region: googleIg11Sheet(iSO3: { eq: $region }) {
//   id
//   memberState
//   iSO3

//   totalDeathAvert2019
//   totalDeathAvert2020
//   totalDeathAvert2021
//   totalDeathAvert2022
//   totalDeathAvert2023
//   totalDeathAvert2024
//   totalDeathAvert2025
//   totalDeathAvert2026
//   totalDeathAvert2027
//   totalDeathAvert2028
//   totalDeathAvert2029
//   totalDeathAvert2030
// }
// ig12Region: googleIg12Sheet(iSO3: { eq: $region }) {
//   id
//   memberState
//   iSO3
//   regionType
//   region

//   measles2020NotEndorsed
//   measles2020ReEstablished
//   measles2020NewlyAchieved
//   measles2020Achieved
//   measles2020NotAchieved
//   measles2021NotEndorsed
//   measles2021ReEstablished
//   measles2021NewlyAchieved
//   measles2021Achieved
//   measles2021NotAchieved
//   measles2022NotEndorsed
//   measles2022ReEstablished
//   measles2022NewlyAchieved
//   measles2022Achieved
//   measles2022NotAchieved
//   measles2023NotEndorsed
//   measles2023ReEstablished
//   measles2023NewlyAchieved
//   measles2023Achieved
//   measles2023NotAchieved
//   measles2024NotEndorsed
//   measles2024ReEstablished
//   measles2024NewlyAchieved
//   measles2024Achieved
//   measles2024NotAchieved
//   measles2025NotEndorsed
//   measles2025ReEstablished
//   measles2025NewlyAchieved
//   measles2025Achieved
//   measles2025NotAchieved
//   measles2026NotEndorsed
//   measles2026ReEstablished
//   measles2026NewlyAchieved
//   measles2026Achieved
//   measles2026NotAchieved
//   measles2027NotEndorsed
//   measles2027ReEstablished
//   measles2027NewlyAchieved
//   measles2027Achieved
//   measles2027NotAchieved
//   measles2028NotEndorsed
//   measles2028ReEstablished
//   measles2028NewlyAchieved
//   measles2028Achieved
//   measles2028NotAchieved
//   measles2029NotEndorsed
//   measles2029ReEstablished
//   measles2029NewlyAchieved
//   measles2029Achieved
//   measles2029NotAchieved
//   measles2030NotEndorsed
//   measles2030ReEstablished
//   measles2030NewlyAchieved
//   measles2030Achieved
//   measles2030NotAchieved

//   rubella2020NotEndorsed
//   rubella2020ReEstablished
//   rubella2020NewlyAchieved
//   rubella2020Achieved
//   rubella2020NotAchieved
//   rubella2021NotEndorsed
//   rubella2021ReEstablished
//   rubella2021NewlyAchieved
//   rubella2021Achieved
//   rubella2021NotAchieved
//   rubella2022NotEndorsed
//   rubella2022ReEstablished
//   rubella2022NewlyAchieved
//   rubella2022Achieved
//   rubella2022NotAchieved
//   rubella2023NotEndorsed
//   rubella2023ReEstablished
//   rubella2023NewlyAchieved
//   rubella2023Achieved
//   rubella2023NotAchieved
//   rubella2024NotEndorsed
//   rubella2024ReEstablished
//   rubella2024NewlyAchieved
//   rubella2024Achieved
//   rubella2024NotAchieved
//   rubella2025NotEndorsed
//   rubella2025ReEstablished
//   rubella2025NewlyAchieved
//   rubella2025Achieved
//   rubella2025NotAchieved
//   rubella2026NotEndorsed
//   rubella2026ReEstablished
//   rubella2026NewlyAchieved
//   rubella2026Achieved
//   rubella2026NotAchieved
//   rubella2027NotEndorsed
//   rubella2027ReEstablished
//   rubella2027NewlyAchieved
//   rubella2027Achieved
//   rubella2027NotAchieved
//   rubella2028NotEndorsed
//   rubella2028ReEstablished
//   rubella2028NewlyAchieved
//   rubella2028Achieved
//   rubella2028NotAchieved
//   rubella2029NotEndorsed
//   rubella2029ReEstablished
//   rubella2029NewlyAchieved
//   rubella2029Achieved
//   rubella2029NotAchieved
//   rubella2030NotEndorsed
//   rubella2030ReEstablished
//   rubella2030NewlyAchieved
//   rubella2030Achieved
//   rubella2030NotAchieved

//   wPV2020NotEndorsed
//   wPV2020ReEstablished
//   wPV2020NewlyAchieved
//   wPV2020Achieved
//   wPV2020NotAchieved
//   wPV2021NotEndorsed
//   wPV2021ReEstablished
//   wPV2021NewlyAchieved
//   wPV2021Achieved
//   wPV2021NotAchieved
//   wPV2022NotEndorsed
//   wPV2022ReEstablished
//   wPV2022NewlyAchieved
//   wPV2022Achieved
//   wPV2022NotAchieved
//   wPV2023NotEndorsed
//   wPV2023ReEstablished
//   wPV2023NewlyAchieved
//   wPV2023Achieved
//   wPV2023NotAchieved
//   wPV2024NotEndorsed
//   wPV2024ReEstablished
//   wPV2024NewlyAchieved
//   wPV2024Achieved
//   wPV2024NotAchieved
//   wPV2025NotEndorsed
//   wPV2025ReEstablished
//   wPV2025NewlyAchieved
//   wPV2025Achieved
//   wPV2025NotAchieved
//   wPV2026NotEndorsed
//   wPV2026ReEstablished
//   wPV2026NewlyAchieved
//   wPV2026Achieved
//   wPV2026NotAchieved
//   wPV2027NotEndorsed
//   wPV2027ReEstablished
//   wPV2027NewlyAchieved
//   wPV2027Achieved
//   wPV2027NotAchieved
//   wPV2028NotEndorsed
//   wPV2028ReEstablished
//   wPV2028NewlyAchieved
//   wPV2028Achieved
//   wPV2028NotAchieved
//   wPV2029NotEndorsed
//   wPV2029ReEstablished
//   wPV2029NewlyAchieved
//   wPV2029Achieved
//   wPV2029NotAchieved
//   wPV2030NotEndorsed
//   wPV2030ReEstablished
//   wPV2030NewlyAchieved
//   wPV2030Achieved
//   wPV2030NotAchieved

//   jev2020NotEndorsed
//   jev2020ReEstablished
//   jev2020NewlyAchieved
//   jev2020Achieved
//   jev2020NotAchieved
//   jev2021NotEndorsed
//   jev2021ReEstablished
//   jev2021NewlyAchieved
//   jev2021Achieved
//   jev2021NotAchieved
//   jev2022NotEndorsed
//   jev2022ReEstablished
//   jev2022NewlyAchieved
//   jev2022Achieved
//   jev2022NotAchieved
//   jev2023NotEndorsed
//   jev2023ReEstablished
//   jev2023NewlyAchieved
//   jev2023Achieved
//   jev2023NotAchieved
//   jev2024NotEndorsed
//   jev2024ReEstablished
//   jev2024NewlyAchieved
//   jev2024Achieved
//   jev2024NotAchieved
//   jev2025NotEndorsed
//   jev2025ReEstablished
//   jev2025NewlyAchieved
//   jev2025Achieved
//   jev2025NotAchieved
//   jev2026NotEndorsed
//   jev2026ReEstablished
//   jev2026NewlyAchieved
//   jev2026Achieved
//   jev2026NotAchieved
//   jev2027NotEndorsed
//   jev2027ReEstablished
//   jev2027NewlyAchieved
//   jev2027Achieved
//   jev2027NotAchieved
//   jev2028NotEndorsed
//   jev2028ReEstablished
//   jev2028NewlyAchieved
//   jev2028Achieved
//   jev2028NotAchieved
//   jev2029NotEndorsed
//   jev2029ReEstablished
//   jev2029NewlyAchieved
//   jev2029Achieved
//   jev2029NotAchieved
//   jev2030NotEndorsed
//   jev2030ReEstablished
//   jev2030NewlyAchieved
//   jev2030Achieved
//   jev2030NotAchieved

//   hpv2020NotEndorsed
//   hpv2020ReEstablished
//   hpv2020NewlyAchieved
//   hpv2020Achieved
//   hpv2020NotAchieved
//   hpv2021NotEndorsed
//   hpv2021ReEstablished
//   hpv2021NewlyAchieved
//   hpv2021Achieved
//   hpv2021NotAchieved
//   hpv2022NotEndorsed
//   hpv2022ReEstablished
//   hpv2022NewlyAchieved
//   hpv2022Achieved
//   hpv2022NotAchieved
//   hpv2023NotEndorsed
//   hpv2023ReEstablished
//   hpv2023NewlyAchieved
//   hpv2023Achieved
//   hpv2023NotAchieved
//   hpv2024NotEndorsed
//   hpv2024ReEstablished
//   hpv2024NewlyAchieved
//   hpv2024Achieved
//   hpv2024NotAchieved
//   hpv2025NotEndorsed
//   hpv2025ReEstablished
//   hpv2025NewlyAchieved
//   hpv2025Achieved
//   hpv2025NotAchieved
//   hpv2026NotEndorsed
//   hpv2026ReEstablished
//   hpv2026NewlyAchieved
//   hpv2026Achieved
//   hpv2026NotAchieved
//   hpv2027NotEndorsed
//   hpv2027ReEstablished
//   hpv2027NewlyAchieved
//   hpv2027Achieved
//   hpv2027NotAchieved
//   hpv2028NotEndorsed
//   hpv2028ReEstablished
//   hpv2028NewlyAchieved
//   hpv2028Achieved
//   hpv2028NotAchieved
//   hpv2029NotEndorsed
//   hpv2029ReEstablished
//   hpv2029NewlyAchieved
//   hpv2029Achieved
//   hpv2029NotAchieved
//   hpv2030NotEndorsed
//   hpv2030ReEstablished
//   hpv2030NewlyAchieved
//   hpv2030Achieved
//   hpv2030NotAchieved

//   tetanus2020NotEndorsed
//   tetanus2020ReEstablished
//   tetanus2020NewlyAchieved
//   tetanus2020Achieved
//   tetanus2020NotAchieved
//   tetanus2021NotEndorsed
//   tetanus2021ReEstablished
//   tetanus2021NewlyAchieved
//   tetanus2021Achieved
//   tetanus2021NotAchieved
//   tetanus2022NotEndorsed
//   tetanus2022ReEstablished
//   tetanus2022NewlyAchieved
//   tetanus2022Achieved
//   tetanus2022NotAchieved
//   tetanus2023NotEndorsed
//   tetanus2023ReEstablished
//   tetanus2023NewlyAchieved
//   tetanus2023Achieved
//   tetanus2023NotAchieved
//   tetanus2024NotEndorsed
//   tetanus2024ReEstablished
//   tetanus2024NewlyAchieved
//   tetanus2024Achieved
//   tetanus2024NotAchieved
//   tetanus2025NotEndorsed
//   tetanus2025ReEstablished
//   tetanus2025NewlyAchieved
//   tetanus2025Achieved
//   tetanus2025NotAchieved
//   tetanus2026NotEndorsed
//   tetanus2026ReEstablished
//   tetanus2026NewlyAchieved
//   tetanus2026Achieved
//   tetanus2026NotAchieved
//   tetanus2027NotEndorsed
//   tetanus2027ReEstablished
//   tetanus2027NewlyAchieved
//   tetanus2027Achieved
//   tetanus2027NotAchieved
//   tetanus2028NotEndorsed
//   tetanus2028ReEstablished
//   tetanus2028NewlyAchieved
//   tetanus2028Achieved
//   tetanus2028NotAchieved
//   tetanus2029NotEndorsed
//   tetanus2029ReEstablished
//   tetanus2029NewlyAchieved
//   tetanus2029Achieved
//   tetanus2029NotAchieved
//   tetanus2030NotEndorsed
//   tetanus2030ReEstablished
//   tetanus2030NewlyAchieved
//   tetanus2030Achieved
//   tetanus2030NotAchieved

//   hepb2020NotEndorsed
//   hepb2020ReEstablished
//   hepb2020NewlyAchieved
//   hepb2020Achieved
//   hepb2020NotAchieved
//   hepb2021NotEndorsed
//   hepb2021ReEstablished
//   hepb2021NewlyAchieved
//   hepb2021Achieved
//   hepb2021NotAchieved
//   hepb2022NotEndorsed
//   hepb2022ReEstablished
//   hepb2022NewlyAchieved
//   hepb2022Achieved
//   hepb2022NotAchieved
//   hepb2023NotEndorsed
//   hepb2023ReEstablished
//   hepb2023NewlyAchieved
//   hepb2023Achieved
//   hepb2023NotAchieved
//   hepb2024NotEndorsed
//   hepb2024ReEstablished
//   hepb2024NewlyAchieved
//   hepb2024Achieved
//   hepb2024NotAchieved
//   hepb2025NotEndorsed
//   hepb2025ReEstablished
//   hepb2025NewlyAchieved
//   hepb2025Achieved
//   hepb2025NotAchieved
//   hepb2026NotEndorsed
//   hepb2026ReEstablished
//   hepb2026NewlyAchieved
//   hepb2026Achieved
//   hepb2026NotAchieved
//   hepb2027NotEndorsed
//   hepb2027ReEstablished
//   hepb2027NewlyAchieved
//   hepb2027Achieved
//   hepb2027NotAchieved
//   hepb2028NotEndorsed
//   hepb2028ReEstablished
//   hepb2028NewlyAchieved
//   hepb2028Achieved
//   hepb2028NotAchieved
//   hepb2029NotEndorsed
//   hepb2029ReEstablished
//   hepb2029NewlyAchieved
//   hepb2029Achieved
//   hepb2029NotAchieved
//   hepb2030NotEndorsed
//   hepb2030ReEstablished
//   hepb2030NewlyAchieved
//   hepb2030Achieved
//   hepb2030NotAchieved

//   mNT2021NotEndorsed
//   mNT2021ReEstablished
//   mNT2021NewlyAchieved
//   mNT2021Achieved
//   mNT2021NotAchieved
//   mNT2022NotEndorsed
//   mNT2022ReEstablished
//   mNT2022NewlyAchieved
//   mNT2022Achieved
//   mNT2022NotAchieved
//   mNT2023NotEndorsed
//   mNT2023ReEstablished
//   mNT2023NewlyAchieved
//   mNT2023Achieved
//   mNT2023NotAchieved
//   mNT2024NotEndorsed
//   mNT2024ReEstablished
//   mNT2024NewlyAchieved
//   mNT2024Achieved
//   mNT2024NotAchieved
//   mNT2025NotEndorsed
//   mNT2025ReEstablished
//   mNT2025NewlyAchieved
//   mNT2025Achieved
//   mNT2025NotAchieved
//   mNT2026NotEndorsed
//   mNT2026ReEstablished
//   mNT2026NewlyAchieved
//   mNT2026Achieved
//   mNT2026NotAchieved
//   mNT2027NotEndorsed
//   mNT2027ReEstablished
//   mNT2027NewlyAchieved
//   mNT2027Achieved
//   mNT2027NotAchieved
//   mNT2028NotEndorsed
//   mNT2028ReEstablished
//   mNT2028NewlyAchieved
//   mNT2028Achieved
//   mNT2028NotAchieved
//   mNT2029NotEndorsed
//   mNT2029ReEstablished
//   mNT2029NewlyAchieved
//   mNT2029Achieved
//   mNT2029NotAchieved
//   mNT2030NotEndorsed
//   mNT2030ReEstablished
//   mNT2030NewlyAchieved
//   mNT2030Achieved
//   mNT2030NotAchieved
// }
// ig13Region: googleIg13Sheet(iSO3: { eq: $region }) {
//   id
//   memberState
//   iSO3
//   regionType
//   region

//   choleraBaseline
//   cholera2021
//   cholera2022
//   cholera2023
//   cholera2024
//   cholera2025
//   cholera2026
//   cholera2027
//   cholera2028
//   cholera2029
//   cholera2030

//   ebolaBaseline
//   ebola2021
//   ebola2022
//   ebola2023
//   ebola2024
//   ebola2025
//   ebola2026
//   ebola2027
//   ebola2028
//   ebola2029
//   ebola2030

//   measlesBaseline
//   measles2021
//   measles2022
//   measles2023
//   measles2024
//   measles2025
//   measles2026
//   measles2027
//   measles2028
//   measles2029
//   measles2030

//   meningBaseline
//   mening2021
//   mening2022
//   mening2023
//   mening2024
//   mening2025
//   mening2026
//   mening2027
//   mening2028
//   mening2029
//   mening2030

//   cVDPVBaseline
//   cVDPV2021
//   cVDPV2022
//   cVDPV2023
//   cVDPV2024
//   cVDPV2025
//   cVDPV2026
//   cVDPV2027
//   cVDPV2028
//   cVDPV2029
//   cVDPV2030

//   wPVBaseline
//   wPV2021
//   wPV2022
//   wPV2023
//   wPV2024
//   wPV2025
//   wPV2026
//   wPV2027
//   wPV2028
//   wPV2029
//   wPV2030

//   yellowFeverBaseline
//   yellowFever2021
//   yellowFever2022
//   yellowFever2023
//   yellowFever2024
//   yellowFever2025
//   yellowFever2026
//   yellowFever2027
//   yellowFever2028
//   yellowFever2029
//   yellowFever2030
// }
// ig21Region: googleIg21Sheet(iSO3: { eq: $region }) {
//   id
//   iSO3
//   region
//   memberState
//   regionType
//   zeroDose2019
//   zeroDose2020
//   zeroDose2021
//   zeroDose2022
//   zeroDose2023
//   zeroDose2024
//   zeroDose2025
//   zeroDose2026
//   zeroDose2027
//   zeroDose2028
//   zeroDose2029
// }
// ig22Region: googleIg22Sheet(iSO3: { eq: $region }) {
//   id
//   memberState
//   iSO3
//   region
//   regionType
//   vaccineIntro2020
//   vaccineIntro2021
//   vaccineIntro2022
//   vaccineIntro2023
//   vaccineIntro2024
//   vaccineIntro2025
//   vaccineIntro2026
//   vaccineIntro2027
//   vaccineIntro2028
//   vaccineIntro2029
//   vaccineIntro2030
// }
// allCountries: allGoogleIg12Sheet {
//   nodes {
//     id
//     memberState
//     iSO3
//     region
//     regionType
//   }
// }
// sp12Region: googleSp12Sheet(iSO3: { eq: $region }) {
//   id
//   iSO3
//   region
//   memberState
//   phyNurseMidwive2020
//   phyNurseMidwive2021
//   phyNurseMidwive2022
//   phyNurseMidwive2023
//   phyNurseMidwive2024
//   phyNurseMidwive2025
//   phyNurseMidwive2026
//   phyNurseMidwive2027
//   phyNurseMidwive2028
//   phyNurseMidwive2029
//   phyNurseMidwive2030
// }
// sp16Region: googleSp16Sheet(iSO3: { eq: $region }) {
//   id
//   memberState
//   iSO3
//   region
//   regionType
//   vigiAEFI2021
//   vigiAEFI2022
// }
// sp21Region: googleSp21Sheet(iSO3: { eq: $region }) {
//   id
//   iSO3
//   region
//   memberState
//   yes2021
//   no2021
//   nDNRNoResponse2021
//   yes2022
//   no2022
//   nDNRNoResponse2022
// }
// sp22Region: googleSp22Sheet(iSO3: { eq: $region }) {
//   id
//   iSO3
//   region
//   memberState
//   yes2021
//   no2021
//   nDNRNoResponse2021
//   yes2022
//   no2022
//   nDNRNoResponse2022
// }
// sp41Region: googleSp41Sheet(iSO3: { eq: $region }) {
//   id
//   iSO3
//   region
//   memberState
//   regionType
//   bOP2019
//   bOP2020
//   bOP2021
//   bOP2022
//   bOP2023
//   bOP2024
//   bOP2025
//   bOP2026
//   bOP2027
//   bOP2028
//   bOP2029
//   bOP2030
// }
// ig32Region: googleIg32Sheet(iSO3: { eq: $region }) {
//   id
//   memberState
//   iSO3
//   region
//   regionType
//   uhcSci2019

//   uhcSci2021

//   uhcSci2023

//   uhcSci2025

//   uhcSci2027

//   uhcSci2029
// }
// sp32Region: googleSp32Sheet(iSO3: { eq: $region }) {
//   id
//   iSO3
//   region
//   memberState
//   dTPCV32021
//   dTPCV32022
//   dTPCV32023
//   dTPCV32024
//   dTPCV32025
//   dTPCV32026
//   dTPCV32027
//   dTPCV32028
//   dTPCV32029
//   dTPCV32030

//   mCV12021
//   mCV12022
//   mCV12023
//   mCV12024
//   mCV12025
//   mCV12026
//   mCV12027
//   mCV12028
//   mCV12029
//   mCV12030

//   mCV22021
//   mCV22022
//   mCV22023
//   mCV22024
//   mCV22025
//   mCV22026
//   mCV22027
//   mCV22028
//   mCV22029
//   mCV22030
// }
// sp71Region: googleSp71Sheet(iSO3: { eq: $region }) {
//   id
//   iSO3
//   region
//   memberState
//   yes2021
//   no2021
//   nDNRNoResponse2021
//   yes2022
//   no2022
//   nDNRNoResponse2022
// }
