import styled from 'styled-components'
import { MediaQueries as Mq } from '../styles/MediaQueries'
import { Link } from 'gatsby-plugin-react-i18next'

export const GoalTitle = styled.div`
  font-size: 1.375rem;
  color: var(--darkGray);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  ${Mq.md} {
  }
  ${Mq.sm} {
    font-size: 1.2rem;
  }
  ${Mq.xs} {
    font-size: 1rem;
    padding-right: 10px;
  }
  .tooltip {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
  }
`

export const LabelBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.25rem;
  height: 2.75rem;
  background-color: ${({ bgColor }) => `var(--${bgColor})`};
  color: ${({ color }) => `var(--${color})`};
  border-radius: 0.25rem;
  font-family: 'Bebas Neue';
  font-size: 1.3rem; //${({ size }) => (size === 'sm' ? '1.375rem' : '1.5rem')};
  font-weight: normal;
  line-height: 1;
  text-align: center;
  padding-top: 0.1rem;
  border: ${({ border }) => (border ? '1px solid var(--brightBlue)' : 'none')};
  ${Mq.sm} {
    width: 5.75rem;
    font-size: 1.4rem;
  }
  ${Mq.xs} {
    width: 3.25rem;
    height: 2rem;
    font-size: 0.9rem;
    line-height: 1.1;
    padding: 5px;
  }
`
export const NotMeasured = styled.div`
  text-align: center;
  font-size: 0.9rem;
  width: 6.25rem;
	${Mq.sm} {
		width: 5.75rem;
		font-size: 0.8rem;
	}
	${Mq.xs} {
		width: 3rem;
		font-size: 0.65rem;
	}
}
`

export const DataSrcYear = styled.strong`
  font-size: 1.25rem;
  ${Mq.sm} {
    font-size: 1rem;
  }
`
export const DataSrc = styled.span`
  font-size: 1rem;

  ${Mq.sm} {
    font-size: 0.75rem;
  }
`
export const DataSrcLink = styled(Link)`
  font-size: 1rem;
  color: var(--baseBlue);
  ${Mq.sm} {
    font-size: 0.75rem;
  }
`
export const Disclaimer = styled.div`
  font-size: 1rem;
  ${Mq.sm} {
    font-size: 0.75rem;
  }
`
export const DataSrcContainer = styled.div`
  line-height: 1.1;
  padding-bottom: 0.5rem;
`
