import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'
//import { MediaQueries as Mq } from '../../styles/MediaQueries'
import { useMediaQuery } from 'react-responsive'
import { GoalId } from '../../styles/OverviewStyles'
import {
  GoalTitle,
  LabelBox,
  NotMeasured
} from '../../styles/CountryPageStyles'
import { format } from 'd3-format'
import DataSource from '../DataSource'
import { useTranslation } from 'react-i18next'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  &.side-border {
    border-right: 1px solid var(--lightGray);
  }
`

const Ig11CountryPageChart = ({
  data,
  dataSrc,
  content,
  compareTo,
  isClient
}) => {
  const { t } = useTranslation()
  const matches = useMediaQuery({ query: '(max-width: 900px)' })
  const sorted = data.sort((a, b) => (a.YEAR < b.YEAR ? -1 : 1))
  const total = sorted[sorted.length - 1].VALUE_NUM
  const year = sorted[sorted.length - 1].YEAR
  const formatThous = format(',.0f')

  return (
    <>
      <GoalId>1.1</GoalId>

      <GoalTitle className={'wide'}>
        {content.ig11Description}
        <DataSource year={year} dataSrc={dataSrc} indicator={'ig1.1'} />
      </GoalTitle>

      {matches && <Flex></Flex>}
      <Flex className="side-border">
        <NotMeasured>{t('not measured at the country level')}</NotMeasured>
      </Flex>
      <Flex>
        {isClient && compareTo ? (
          <NotMeasured>{t('not measured at the country level')}</NotMeasured>
        ) : (
          <LabelBox bgColor={'lightestGray'}>{formatThous(total)}</LabelBox>
        )}
      </Flex>
    </>
  )
}

export default Ig11CountryPageChart
